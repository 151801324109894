import React, {useState, FC} from 'react';

import ServiceForm from './ServiceForm';
import ServiceFormConformation from './ServiceFormConformation';
import { campaignInfoData } from '../../assets/data/red-ventures/campaignInfoData';
import { overviewData } from '../../assets/data/red-ventures/overviewData';
import Layout from '../../layout/main-empty';
import SEOComponent from '../../components/seo';

export interface BlobCollection {
    [name: string]: Blob;
}

export interface FormDataType {
    name: string;
    title: string;
    type?: string;
    content?: string | string[];
};

const RedVenturesForm : FC = () => {   
    const [showServiceForm, setShowServiceForm] =useState<boolean> (true);
    const [imageFile, setImageFile] = useState<BlobCollection>({});
   
    const initialOverview = initialDataHelper(overviewData)
    const overviewInput = formDataHelper('overviewData', initialOverview);
    const initialCampaignInfo = initialDataHelper(campaignInfoData);
    const campaignInfoInput = formDataHelper('campaignInfo', initialCampaignInfo);

    const updateDisplay = (shouldShowServiceForm: boolean):void => {
        setShowServiceForm(shouldShowServiceForm);
    }

    const updateImageFile = (images: BlobCollection) : void => {
        setImageFile(images);        
    }

    return (
        <Layout mainBackgroundColor='#eee'>
            <SEOComponent
                title="Red Ventures Client Form"
                description="Red Ventures Client Form"
            />
            {
            showServiceForm ? 
            <ServiceForm 
                updateDisplay={updateDisplay}
                updateImageFile={updateImageFile}
                imageFile={imageFile}
                overviewSaved={overviewInput}
                campaignInfoSaved={campaignInfoInput}       
            /> :
            <ServiceFormConformation
                overviewInput={overviewInput}  
                campaignInfoInput={campaignInfoInput} 
                updateDisplay={updateDisplay}
                imageFile={imageFile}
            />
            }                        
        </Layout>
    );   
}

export const initialDataHelper = (dataSource: FormDataType[]) : Input=> {
    let initialData = {};
    dataSource.forEach (d => {
        initialData[d.name]= '';
    });

    return initialData
}

const formDataHelper = (key: string, initialData: Input) => {
    if(typeof window !== "undefined") {
        const oData = localStorage.getItem(key);
        const formInput = (oData !== undefined && oData !== null && oData !== '') ? JSON.parse(oData) : initialData;
        if(formInput !== null) return formInput;
        return formInput;        
    } 
    return initialData;
}

export default RedVenturesForm;
