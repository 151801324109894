import React, { useState, ReactElement, FC, Dispatch, SetStateAction, BaseSyntheticEvent } from 'react';

import { Link } from 'gatsby';
import { FaFileImage } from "react-icons/fa";
import styled from 'styled-components';

import { initialDataHelper, BlobCollection } from '../red-ventures-service-form';
import { audience } from '../../assets/data/red-ventures/audience';
import { campaignInfo } from '../../assets/data/red-ventures/campaignInfo';
import { campaignInfoData } from '../../assets/data/red-ventures/campaignInfoData';
import { overviewData } from '../../assets/data/red-ventures/overviewData';
import Logo from '../../assets/images/logo.png';
import { states } from '../../utils/us-states';

const FormPanel = styled.div`
    font-family: Arial, Helvetica, sans-serif;
`;

const Header = styled.div`
    background-color: ${(props: Props) => props.bgColor};
    padding: 30px;
    text-align: left;
    img {
        width: 100px;
        height: auto;
    } 
`;

const ButtonGroup = styled.div`
    margin-left: 70%;
    display: flex;
    flex-direction: row;   
    text-align: center;
    @media screen and (max-width: 1440px){
        margin-left: 70%;
    }
    @media screen and (max-width: 1040px){
        margin-left: 65%;
    }
    @media screen and (max-width: 767px){
        margin-left: 58%;
    }
    .continue, .help{
        text-decoration: none;
    }
    .button {
        font-size: 17px;
        width: 22%;
        border-radius: 7px;
        background-color: #d1ab65;
        border: 2px solid #aa9468;
        color: white;       
        margin: 0px 1px 0px 1px;
        padding-top: 8px;
        padding-bottom: 8px; 
        @media screen and (max-width: 1440px){
            font-size: 15px;
        }
        @media screen and (max-width: 1040px){
            width: 24%;
            font-size: 13px;  
        }
        @media screen and (max-width: 767px){
            width: 25%;
            font-size: 11px;
        }  
        a {
            color: white;
            text-decoration: none;
        }     
    }   
`;

const ServiceFormPanel = styled.form`
    margin: 5px;
    padding-bottom: 20px;
`;

const Overview = styled.fieldset`
    padding: 0px 30px 5px 30px;
    border: hidden;   
`;

const OverviewTitle = styled.div`
    font-size: 20px;
    font-style: italic;
    font-weight: 550;
`;

const OverviewTitleMain = styled.span`
    color: #d98716;
`;

const OverviewSection = styled.p`
    display: flex;
    flex-direction: row;
    line-height: 1.5em;
`;

const OverviewLabel = styled.label`
    width: 30%;
`;

const OverviewInput = styled.input`
    width: 60%;
    border: hidden;
    padding: 5px 5px 5px 15px;
    margin-left: 15px;
    border-bottom: 1px solid black; 
`;

const CampaignInfo = styled.fieldset`
    padding: 20px;
    display: flex;
    flex-direction: column;
    line-height: 1.8em;
    border: hidden;
`;

const CampaignTitle = styled.div`
    font-size: 20px;
    display: flex;
    flex-direction: row;
    color: #d98716;
    font-style: italic;
    font-weight: 550;
`
const CampaignInfoTitle = styled.div`
    width: 55%;
    border-bottom: 1px solid black; 
`;

const CampaignAnswersTitle = styled.div`
    width: 45%;
    border-bottom: 1px solid black; 
`;

const CampaignSection = styled.div`
 display: flex;
 flex-direction: row;
 border-bottom: 1px solid black;  
`;

const SectionSimple = styled.div`
    width: 50%; 
    border-right: 1px solid black; 
    padding: 8px 0px 8px 10px;
    input {
        border: hidden; 
        width: 94%;
        font-size: 16px;
        height: 85%;
        padding: 1% 1% 1% 5%;
        background-color: #eee;
        opacity: 0.8;
    } 
`;

const SectionInput = styled.div`
    width: 50%; 
    border-right: 1px solid black; 
    text-align: left;
    padding: 8px 0px 8px 10px;
    input {
        font-size: 16px;
          
        height: 95%;
        width: 95%;
        padding-left: 20px;
        border: 1px solid black;
        @media screen and (max-width: 1040px){
            width: 90%;
            font-size: 14px;
        }
        @media screen and (max-width: 767px) {
            width: 88%;
            font-size: 13px;
        }
    } 
    select {
        font-size: 16px;
        width: 98%;
        height: 40px;
        opacity: 0.8;
        padding-left: 20px;
        border: 1px solid black;
    }

    option {
        margin-left: -20px;
        padding-left: 15px;       
    }
    option:checked {
        background-color: #cccbc5;    
    }
`;

const SectionInputComplex = styled.div`
    width: 50%; 
    border-right: 1px solid black; 
    padding: 20px 0px 8px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const Calendar = styled.div`
    width: 40%;
    span {
        padding-right: 15px;
    }
`;

const Choice = styled.div`
   padding: 2%; 
`;

const SectionFileInput = styled.div`
    width: 50%; 
    border-right: 1px solid black; 
    text-align: center;
    padding: 30px 0px 8px 10px;
    input {
        border: hidden; 
        width: 93%;
        font-size: 16px;
        min-height: 10%;
        padding: 1% 1% 1% 5%;
    } 
    li{
        list-style-type: none;
        text-align: left;
    }
`;

const SectionContent = styled.div`
    width: 55%; 
    padding-left: 10px;
    border-right: 1px solid black;
    border-left: 1px solid black;     
`;

const Error = styled.div`
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 5%;
    padding: 2px 5px 2px 5px;
    @media screen and (max-width: 1040px) {
        margin-left: 3%;
    }
    @media screen and (max-width: 767px) {
        margin-left:2%;
        font-size: 14px;
    }
    @media screen and (max-width: 480px) {
        margin-left: 10px;
        font-size:12px;
    }
`;

interface Input {
    [name: string]: string | url | number | string[];
};

interface Props {
    mainBackgroundColor?: string;
    bgColor?: string;
    imageFile: BlobCollection;
    overviewSaved: Input;
    campaignInfoSaved: Input;
    updateDisplay: (arg: boolean) => void;
    updateImageFile: (arg: BlobCollection) => void;
};

interface CampaignField {
    name: string;
    content: string[];
}
const CPM = 12;

const ServiceForm : FC<Props> = ({mainBackgroundColor, imageFile, overviewSaved, campaignInfoSaved, updateDisplay, updateImageFile}) => {

    const [overviewInput, setOverviewInput] = useState<Input>(overviewSaved);
    const [campaignInfoInput, setCampaignInfoInput] = useState<Input>(campaignInfoSaved);

    const [minEndDate, setMinEndDate] = useState<string>(beginningDate(Date.now(), 4))
  
    const [requiredOverviewFieldError, setRequiredOverviewError] = useState<string>('');
    const [requiredCampaignFieldError, setRequiredCampaignError] = useState<string>('');
    const [budgetNotNumberError, setBudgetNotNumberError] = useState<string>('');
    const [budgetError, setBudgetError] = useState<string>('');
    const [endDateError, setEndDateError] = useState<string>('');
    const [adSiteUrlError, setAdSiteUrlError] = useState<string>('');
    const [landingPageUrlError, setLandingPageUrlError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [fileSizeError, setFileSizeError] = useState<string>('');
    const [imageSizeError, setImageSizeError] = useState<string>('');
    const [requiredImageFieldError, setRequiredImageError] = useState<string>('');

    const overviewInputValidation = () : boolean => {
        const fieldRequiredError : boolean = nullValidation(overviewInput, 'Overview', setRequiredOverviewError);

        const isUrl = urlValidation('advertiser website', overviewInput['adSite'], setAdSiteUrlError);

        const isEmail =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(overviewInput['contactsEmail']);
        if(overviewInput['contactsEmail'].length !== 0 && !isEmail) setEmailError('You must input a valid email address!');

        return !fieldRequiredError  && isUrl && isEmail  && budgetValidation(overviewInput['budget'], setBudgetNotNumberError, setBudgetError);
    }

    const recheckOverviewFormValidation = (name : string, value : string) : void => {
        if(adSiteUrlError !== '' && name === 'adSite') {
            urlValidation('Advertiser Website', overviewInput[name], setAdSiteUrlError);
        }

        if(emailError !== '' && name === 'contactsEmail'){
            // reference link: https://www.w3resource.com/javascript/form/email-validation.php
            const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
            if(isEmail)setEmailError('');
        }

        if( name ==='budget' ){
            const budgetNumGeneral : string = overviewInput?.name?.replace(/,/g, '') | overviewInput;
            const budgetNum = parseFloat(budgetNumGeneral);
            if(budgetNotNumberError){
                if(!isNaN(budgetNum)) setBudgetNotNumberError('');
            }
            if(budgetError){
                if(budgetNum >= 10000) setBudgetError('');
            }
        }

        if(requiredOverviewFieldError !== '') {
            nullValidation(overviewInput, 'Overview', setRequiredOverviewError);
        }
    }

    const required = campaignInfoData.filter( d => d.required=== 'true');
    const requiredFields = required.map(d => campaignInfoInput ? campaignInfoInput[d.name] : []);

    const campaignInfoValidation  = () : boolean => {          
        const fieldRequiredError : boolean = nullValidation(requiredFields, 'Campaign Information', setRequiredCampaignError);
        
        const endDateLaterThanStartDateError : boolean = Date.parse(campaignInfoInput?.endDate) < Date.parse(campaignInfoInput?.startDate);
        if(endDateLaterThanStartDateError) setEndDateError('End Data must be later than Start date');

        const isUrl = urlValidation('landing page', campaignInfoInput?.landingPage, setLandingPageUrlError);

        return !(fieldRequiredError || !isUrl || endDateLaterThanStartDateError);
    }

    const recheckCampaignInfoValidation = (name : string, value : string) : void => {
        if(name === 'endDate' ) {
            const endDateLaterThanStartDateError : boolean = Date.parse(value) < Date.parse(campaignInfoInput?.startDate);
            if(!endDateLaterThanStartDateError)setEndDateError('');
        }

        if(name === 'landingPage' && landingPageUrlError !== '') {
            urlValidation('landing page', campaignInfoInput?.campaignKPI, setLandingPageUrlError);
        }

        if(requiredCampaignFieldError !== ''){
            nullValidation(requiredFields, 'required Campaign Information', setRequiredCampaignError);
        }        
    }

    const imageNullValidation = () : boolean => {
        const imageNames = campaignInfoInput['creativesSpecs'];
        if(imageNames.length === 0) setRequiredImageError("Please load correct images. It is a required field!");

        return  imageNames.length === 0;
    }

    const handleOverviewInput = (e : BaseSyntheticEvent) => {
        let value = e.target.value;
        const name = e.target.name;

        if(name === 'budget' && value !== '') {          
            const newValue = parseFloat(value.replace(/,/g, ''));
            if(!isNaN(newValue)) value = newValue.toLocaleString();
        } 

        setFormData(name, value, overviewInput, setOverviewInput);

        //CampaignInfoInput has impressions controlled by OverviewInput Budget field
       if(name === 'budget') {
            const impressions =  value !== '' ? Math.round(parseFloat(value.replace(/,/g, '')) / CPM * 1000).toLocaleString() : '0';
            setFormData('impressions', impressions, campaignInfoInput, setCampaignInfoInput);
        }
    }

    const handleOverviewInputLeave = (e: BaseSyntheticEvent) => {
        const value = e.target.value;
        const name = e.target.name;  
        recheckOverviewFormValidation(name, value);   
    }

    const handleCampaignInfo = (e : BaseSyntheticEvent) => {             
        const name = e.target.name;
        let value = e.target.value;     
        if(name === 'targetAudience'){
            const selected = [...e.target.options]
                .filter(option => option.selected)
                .map(option => option.value);
            value = selected.join(', ');
        }
        setFormData(name, value, campaignInfoInput, setCampaignInfoInput);
        
        //startDate controls the earliest date the endDate can be
        if(name === 'startDate') setMinEndDate(beginningDate(Date.parse(value), 1));
        recheckCampaignInfoValidation(name, value);
    }

    const handleCampaignInfoLeave = (e : BaseSyntheticEvent) => {
        const name = e.target.name;
        const value = e.target.value;
        recheckCampaignInfoValidation(name, value);
    }

    const uploadImage = async (e : BaseSyntheticEvent) => {
        const file = e.target.files[0];

        const isFileSizeOK = fileSizeValidation(file, setFileSizeError);        
        if(!isFileSizeOK) return;
     
        const sizeLimit= ['300x250','160x600', '728x90', '970x250', '320x50', '300x600'];
        let isImageSizeOK = false;
        let image = new Image();
        let _URL = window.URL || window.webkitURL;
        let objectUrl = _URL.createObjectURL(file);
    
        image.onload = function () {
            isImageSizeOK = sizeLimit.includes(`${image.width}x${image.height}`);
            _URL.revokeObjectURL(objectUrl);
            if(!isImageSizeOK) {
                setImageSizeError("The image size doesn't meet the requirement. Please select another image!");
            } else {
                setImageSizeError('');
                if(requiredImageFieldError) setRequiredImageError('');
              
                setCampaignInfoInput({
                    ...campaignInfoInput,
                    'creativesSpecs': campaignInfoInput?.creativesSpecs ? [...campaignInfoInput.creativesSpecs, file.name] : [file.name]
                });
        
                imageFile[file.name] = file ;
                updateImageFile(imageFile);
            }
        };
        image.src = objectUrl;
    }

    const handleContinue = () => { 
        const validated = overviewInputValidation() && campaignInfoValidation() && !imageNullValidation() ;
        if(!validated) return;

        localStorage.setItem('overviewData', JSON.stringify(overviewInput));
        localStorage.setItem('campaignInfo', JSON.stringify(campaignInfoInput)); 
        updateDisplay(false);
     }
 
     const handleReset = () => {
        localStorage.setItem('overviewData', '');
        localStorage.setItem('campaignInfo', '');

        setOverviewInput(initialDataHelper(overviewData));
        setCampaignInfoInput(initialDataHelper(campaignInfoData));
     }

    const CampaignInfoItem  =  (data: CampaignField) : ReactElement =>
    <SectionContent>
        {
            data.content.map((t : string, i : number) => 
                <div key={`text-${i}`} dangerouslySetInnerHTML={{ __html: t}} />
        )}                        
    </SectionContent>;

    const ControlButton = () =>
    <ButtonGroup>
        <button className="button continue" onClick={handleContinue}>
            Continue
        </button>
        <button type="reset" className="button reset" onClick={handleReset}>Reset</button>
        <button className="button help"> <a href='https://audigent.atlassian.net/servicedesk/customer/portal/1' target='_blank'>Help</a></button>
    </ButtonGroup>;

    return(
        <FormPanel>
            <Header  bgColor={mainBackgroundColor}> 
                <img src={Logo} alt="logo" /> 
            </Header>
            <ControlButton />
            <ServiceFormPanel>
                <Overview>
                    <Error key="error-required">{requiredOverviewFieldError}</Error>
                    <Error key="error-url"> {adSiteUrlError}</Error>
                    <Error key="error-email">{emailError}</Error>
                    <Error key="error-budget">{budgetError}</Error>
                    <Error key="error-budgetnotnumber">{budgetNotNumberError}</Error>   
                    <OverviewTitle> 
                        <OverviewTitleMain>Overview</OverviewTitleMain> (All Required)
                    </OverviewTitle>
                    {overviewData.map((d,i) =>                  
                        <OverviewSection key={d.name}>
                            <OverviewLabel htmlFor="overviewLabel">
                                {d.title} 
                            </OverviewLabel>                
                            <OverviewInput 
                                id={i.toString()} 
                                name={d.name}
                                value={overviewInput !== null && overviewInput !== undefined ? overviewInput[d.name] : ''}
                                onChange={e => handleOverviewInput(e)}
                                onMouseLeave={e => handleOverviewInputLeave(e)}
                                required  
                            />
                        </OverviewSection>                     
                    )}                
                </Overview>
             
                <CampaignInfo>
                    <Error key="error-required">{requiredCampaignFieldError}</Error>
                   <Error key="error-landingPage">{landingPageUrlError}</Error>
                    <Error key="error-requiredImage">{requiredImageFieldError} </Error>
                    <Error key="error-enddate">{endDateError}</Error> 
                    <CampaignTitle>
                        <CampaignInfoTitle> Campaign Information</CampaignInfoTitle>
                        <CampaignAnswersTitle> Answers:</CampaignAnswersTitle>
                    </CampaignTitle>

                    <CampaignSection key={campaignInfo[0].name}>
                        <CampaignInfoItem {...campaignInfo[0]} />                      
                        <SectionSimple>
                            <input type="text" id="impressions" name="impressions" value={`Impressions: ${campaignInfoInput?.impressions}`} readOnly={true}/> 
                        </SectionSimple> 
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[1].name}>
                        <CampaignInfoItem {...campaignInfo[1]} />                    
                        <SectionInput>
                        <label htmlFor="target-audience"> Choose Target Audience (Select Two at Most) </label>  
                            <select 
                                id="target-audience" 
                                name="targetAudience" 
                                onChange={e=>handleCampaignInfo(e)}  
                                onMouseLeave={e=>handleCampaignInfoLeave(e)} 
                                multiple
                            >  
                                {audience.map(d => 
                                    <option key={d} value={d} style = {{backgroundColor: `${campaignInfoInput?.targetAudience?.includes(d) ? "#cccbc5" : "white"}`}}> {d} </option>
                                )} 
                            </select>       
                        </SectionInput> 
                    </CampaignSection>  

                    <CampaignSection key={campaignInfo[2].name}>
                        <CampaignInfoItem {...campaignInfo[2]} /> 
                        <SectionInput> 
                        <label> Choose US State (Select Only One State or All States) </label>
                        <select id="geo-targeting" name="geoTargeting" onChange={e => handleCampaignInfo(e)} onMouseLeave={e=>handleCampaignInfoLeave(e)}>   
                            <option>{campaignInfoInput?.geoTargeting}</option>                          
                            {states.map(d => 
                                <option 
                                    key={d}  
                                    value={d} 
                                    style={{backgroundColor: `${campaignInfoInput?.geoTargeting === d ? "#cccbc5" : "white"}`}}
                                > 
                                    {d} 
                                </option>
                            )} 
                        </select>                     
                        </SectionInput> 
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[3].name}>
                        <CampaignInfoItem {...campaignInfo[3]} />                    
                        <SectionInputComplex>
                            <Calendar>
                                <span>Start Date:</span><input type="date" min={beginningDate(Date.now(), 3)} id="start-date" name="startDate" value={campaignInfoInput?.startDate} onChange={e=>handleCampaignInfo(e)} onMouseLeave={e=>handleCampaignInfoLeave(e)}/>
                            </Calendar>
                            <Calendar>
                                <span>End Date:</span> <input type="date" id="end-date" min={minEndDate} name="endDate" value={campaignInfoInput?.endDate}  onChange={e=>handleCampaignInfo(e)} onMouseLeave={e=>handleCampaignInfoLeave(e)}/> 
                            </Calendar>                            
                        </SectionInputComplex> 
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[4].name}>
                        <CampaignInfoItem {...campaignInfo[4]} />                    
                        <SectionInputComplex>
                            {["Reach", "Delivery", "CTR"].map(d => 
                                <Choice key={d.toLowerCase()}>
                                    <input type="radio" id={d.toLowerCase()} name="campaignKPI" value={d} checked={d===campaignInfoInput?.campaignKPI} onChange={e=>handleCampaignInfo(e)} onMouseLeave={e=>handleCampaignInfoLeave(e)}/><span>{d}</span>
                                </Choice>
                            )}                            
                        </SectionInputComplex> 
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[5].name}>
                        <CampaignInfoItem {...campaignInfo[5]} />
                        <SectionInput>
                            <input type="text" id="landing-page" name="landingPage" value={campaignInfoInput?.landingPage} onChange={e=> {handleCampaignInfo(e)}} onMouseLeave={e=>handleCampaignInfoLeave(e)}/>
                        </SectionInput>                     
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[6].name}>
                        <CampaignInfoItem {...campaignInfo[6]} />                    
                        <SectionFileInput>
                            <input 
                            type="file" 
                            id="creatives-spec" 
                            name="creativesSpecs" 
                            accept="image/png, image/jpeg, image/gif"
                            onChange={uploadImage}
                            multiple />
                            {<Error>{fileSizeError}</Error>}
                            {<Error>{imageSizeError}</Error>}
                            <ul className="image">
                                {campaignInfoInput?.creativesSpecs?.length > 0 && campaignInfoInput?.creativesSpecs?.map((d, i) => <li key={i}><FaFileImage />{d}</li>)}
                            </ul>                           
                        </SectionFileInput> 
                    </CampaignSection>

                    <CampaignSection key={campaignInfo[7].name}>
                        <CampaignInfoItem {...campaignInfo[7]} />
                        <SectionInput>
                            <input type="text" id="addtional" name="additionalInfo" value={campaignInfoInput?.additionalInfo} onChange={e=> handleCampaignInfo(e)} /> 
                        </SectionInput>
                    </CampaignSection>                                   
                </CampaignInfo>

                <ControlButton />
            </ServiceFormPanel>                       
        </FormPanel>      
    );
};

const beginningDate = (time : number, businessDays : number) => {
    const weekday : number = new Date(time).getDay(); // the getDay() returns 0-6
    const addedDays = weekday + businessDays > 5 ? businessDays + 2 : businessDays;

    const minDate : Date = new Date(time + addedDays * 24 *60*60*1000);
    const year = minDate.getFullYear();
    const month = minDate.getMonth() + 1; // the getMonth() returns 0-11
    const monthNum = month < 10 ? '0' + month : month;
    const day = minDate.getDate();
    const dayNum = day < 10 ? '0' + day : day;
    return `${year}-${monthNum}-${dayNum}`;
}

const setFormData = (
    name: string,
    value: string,
    formInput: Input,
    setFormInput: React.Dispatch<React.SetStateAction<Input>>
)  => {
    if(formInput === null) return;
    setFormInput({
        ...formInput,
        [name]: value
    });
}

export const nullValidation = (formData : Input, errorName : string, setRequiredError: Dispatch<SetStateAction<string>>) => {
    let fieldRequiredError = false;

    Object.keys(formData).forEach( k => {
        if(fieldRequiredError) return;
        if(formData[k].length === 0) {
            fieldRequiredError = true;
        }
    })

    if(fieldRequiredError) {
        setRequiredError(`Please fill all required ${errorName} fields!`);
    } else {
        setRequiredError('');
    }
    return fieldRequiredError;
}

const urlValidation = (target : string, value : string, setUrlError : Dispatch<SetStateAction<string>>) : boolean => {
    const isUrl = /^\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);

    const urlError = isUrl ? '' : `You must input a valid ${target} url address!`;
    setUrlError(urlError);

    return isUrl;
}

const budgetValidation = (budgetInput: string, setBudgetNotNumberError: Dispatch<SetStateAction<string>>, setBudgetError : Dispatch<SetStateAction<string>>) : bollean => {

    const budgetNumGeneral : string = budgetInput.replace(/,/g, '');
    const budgetNum = parseFloat(budgetNumGeneral);

    if( budgetNumGeneral.length !== 0 && isNaN(budgetNum)) setBudgetNotNumberError('Budget must be a number!');

    if( budgetNumGeneral.length !== 0 && !isNaN(budgetNum) && budgetNum <10000) setBudgetError('Budget must be greater than 10k!');

    return (budgetInput.length > 0 && !isNaN(budgetNum) && budgetNum > 10000)
}

const fileSizeValidation = (file : any, setFileSizeError : Dispatch<SetStateAction<string>>) : boolean => {
    const fileSizeError = file?.size > 150000 ? 'The file size exceeds the max size limit!' : '';
    setFileSizeError(fileSizeError);

    return fileSizeError === '';
};

export default ServiceForm;
