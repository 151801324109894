export const campaignInfo = [
    {
        name:'Inventory Type',
        content: ['<strong>Inventory Type</strong>: Media will run on R|V Direct inventory and across the R|V Marketplace', '', '<strong>Direct:</strong> CNET or ZDNet', '<strong>R|V Marketplace:</strong> Targeting R|V audiences across the web', '' , 'CPM $x (to be determined)']
    }, 
    {
        name: 'Target Audiences',
        content: ['<strong>Target Audience(s) : Select from the below (Required)</strong>', 'Will need to make a list of 20 audiences available']
    }, {
        name: 'Geo Targeting',
        content: ['<strong>Geo Targeting (Required)</strong>', 'National, State']
    }, {
        name: 'Flight Start & End Dates',
        content: ['<strong>Flight Start & End Dates (Required)</strong>', 'Please specify any special flighting parameters, if applicable']
    }, {
        name: 'Campaign KPIs',
        content: ['<strong>Campaign KPIs: (Required)</strong>', 'Reach : Max audience penetration + low frequency', 'Delivery : Ensure impression delivery', 'CTR : Maximize traffic to landing page']
    }, {
        name: 'Landing Page',
        content: ['<strong>Landing page (Page audiences will be sent when clicking on advertisement) Required</strong>']
    }, {
        name: 'Creatives',
        content: ['<strong>Creative Specs:</strong>','Type: GIF, HTML, JPG, PNG', 'Max Size: 150 kb','Image Sizes: 300x250, 160x600, 728x90, 970x250, 320x50, 300x600']
    },{
        name: 'Additional Information',
        content: ['<strong>Any Additional Information on Plan or Notes</strong>']
    }];