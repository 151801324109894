export const campaignInfoData = [
    {
        name: 'impressions',
        title: 'Impression',
        type: 'text',
        required: 'calculated',
    }, {
        name:'targetAudience',
        title: 'Target Audience',
        type: 'text',
        required: 'true',
    }, {
        name: 'geoTargeting',
        title: 'Geo Targeting',
        type: 'text',
        required: 'true',
    }, {
        name: 'startDate',
        title: 'Start Date',
        type: 'text',
        required: 'true',
    }, {
        name: 'endDate',
        title: 'End Date',
        type: 'text',
        required: 'true',
    }, {
        name: 'campaignKPI',
        title: 'Campaign KPI', 
        type: 'text',
        required: 'true',
    }, {
        name: 'landingPage',
        title: 'Landing Page', 
        type: 'url',
        required: 'true',
    }, {
        name: 'creativesSpecs',
        title: 'Creatives Specs',
        type: 'string',
        required: 'true',
    }, {
        name: 'additionalInfo',
        title: 'Additional Information',
        type: 'text',
        required: 'false'
    }];