/**
* Layout component that has no menu bar
* with plain white background
*
*/
import React from 'react';
import styled from 'styled-components';

import './style.css';

type Props = {
    mainBackgroundColor?: string;
    bgColor?: string;
    children?: JSX.Element|JSX.Element[],
    logoUrl?: string,
    logoAlt?: string,
}
 
const Main = styled.div`
    margin: 0px 0 0 0px;
    background: ${(props: Props) => props.bgColor};
    width: 100%;
`;

const Layout : React.FC<Props> = ({ children, mainBackgroundColor}: Props) => {
    return (
        <React.Fragment>
       
            <Main bgColor ={mainBackgroundColor} >
                {children}
            </Main>            
        </React.Fragment>
     );
 };
 
 export default Layout;