export const overviewData = [
    {
        name: 'advertiserName',
        title: 'Advertiser Name',
        type: 'text'
    }, {
        name:'adSite',
        title: 'Advertiser Website',
        type: 'url'
    }, {
        name: 'PONumber',
        title: 'PO Number',
        type: 'text'
    }, {
        name: 'billingAddress',
        title: 'Billing Address',
        type: 'text'
    }, {
        name: 'billingContact',
        title: 'Billing Contact',
        type: 'text'
    }, {
        name: 'campaignName',
        title: 'Campaign Name', 
        type: 'text'
    },{
        name: 'mainContacts',
        title: 'Main Contacts', 
        type: 'text'
    }, {
        name: 'contactsEmail',
        title: 'Main Contacts Email Address(es)', 
        type: 'email'
    }, {
        name: 'budget',
        title: 'Budget (minimum budget per month 10k)',
        type: 'number'
     }];

